import React, { useCallback } from 'react'
import { TopBarMenuItem } from './TopBarMenuItem'
import { TopNav } from './TopNav'
import { TopNavComplex } from './TopNavComplex'

export const TopBarMenu = ({
  menuItems,
  setShow,
  show,
}: {
  menuItems: any
  setShow: React.Dispatch<React.SetStateAction<string>>
  show: string
}) => {
  const handleMenuClick = useCallback((isActive: boolean, title: string) => {
    if (isActive) {
      setTimeout(() => {
        setShow('')
      }, 150) // Match the animation duration in TopNav and TopNavComplex
    } else {
      // If there's a currently active menu, add a small delay before showing the new one
      if (show) {
        setTimeout(() => {
          setShow(title)
        }, 150) // Match the animation duration in TopNav and TopNavComplex
      } else {
        setShow(title)
      }
    }
  }, [show, setShow])

  return (
    <div className={`flex gap-8 items-center w-max`}>
      {menuItems.map((item: any) => {
        const isActive = show === item.title
        return (
          <div key={item.id} className="relative">
            <TopBarMenuItem
              title={item.title}
              showDropdown={isActive}
              hasDropdown={item.hasDropdown}
              onClick={
                item.hasDropdown
                  ? () => handleMenuClick(isActive, item.title)
                  : undefined
              }
              linkTo={!item.hasDropdown && item.linkto}
            />
            {item.hasDropdown && isActive && (
              <>
                {item.model.apiKey === 'top_navigation_section' && (
                  <TopNav show={show} setShow={setShow} data={item} />
                )}
                {item.model.apiKey === 'top_navigation_section_complex' && (
                  <TopNavComplex show={show} setShow={setShow} data={item} />
                )}
              </>
            )}
          </div>
        )
      })}
    </div>
  )
}
