import React, { useEffect, useRef, useState } from 'react'
import { TopBarButtons } from '.'
// @ts-ignore
import { SmartLink } from '../../Link'
import clsx from 'clsx'
import { TopNav } from './TopNav'
import { useOnClickOutside } from '../../hooks'
import { TopNavComplex } from './TopNavComplex'

export const TopBarMobileSubmenu = ({
  menuItems,
  show,
  setShow,
  submenu,
  setSubmenu,
  topBarButtons,
}: {
  menuItems: any
  show: boolean
  setShow: React.Dispatch<React.SetStateAction<boolean>>
  submenu: string
  setSubmenu: React.Dispatch<React.SetStateAction<string>>
  topBarButtons: any
}) => {
  const [fadeIn, setFadeIn] = useState(false)
  const wrapperRef = useRef(null)
  useOnClickOutside(wrapperRef, () => {
    if (show) {
      setFadeIn(false)
      setTimeout(() => {
        setShow(false)
        setSubmenu('')
      }, 150)
    }
  })

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        setFadeIn(true)
      }, 1)
    } else {
      setFadeIn(false)
    }
  }, [show])

  return (
    <div
      ref={wrapperRef}
      className={clsx(
        'absolute -right-3 top-16 z-20 max-h-nav overflow-x-hidden overflow-y-scroll rounded-[2rem] bg-white p-6 pt-[1.625rem] transition-opacity duration-300 md:right-0 md:px-14',
        {
          'opacity-0': !fadeIn,
          'opacity-1': fadeIn,
        },
      )}
    >
      <div className="grid grid-cols-1 gap-8 justify-center w-80">
        {menuItems.map((item: any) => {
          const isActive = item.title === submenu

          return (
            <div key={item.id}>
              <SmartLink
                linkTo={!item.hasDropdown && item.linkto}
                className={clsx(
                  'text-20px flex cursor-pointer gap-1 font-semibold text-gray-900',
                  'w-fit border-b border-solid no-underline',
                  {
                    // 'underline decoration-blue-700 underline-offset-4':
                    'border-blue-800': item.hasDropdown && isActive,
                    // 'no-underline': !item.hasDropdown || !isActive,
                    'border-transparent': !item.hasDropdown || !isActive,
                  },
                )}
                onClick={
                  item.hasDropdown
                    ? () => setSubmenu(isActive ? '' : item.title)
                    : undefined
                }
              >
                <div>
                  {item.title}{' '}
                  <span
                    className={clsx(
                      'inline-block font-bold text-blue-700 transition-all duration-300',
                      {
                        'rotate-90': isActive,
                        '-rotate-90': !isActive && item.hasDropdown,
                      },
                    )}
                  >
                    {'>'}
                  </span>
                </div>
              </SmartLink>

              {isActive && item.hasDropdown && (
                <>
                  {item.model.apiKey === 'top_navigation_section' && (
                    <TopNav show={submenu} setShow={() => null} data={item} />
                  )}
                  {item.model.apiKey === 'top_navigation_section_complex' && (
                    <TopNavComplex
                      show={submenu}
                      setShow={() => null}
                      data={item}
                    />
                  )}
                </>
              )}
            </div>
          )
        })}
        <div className="justify-center">
          <TopBarButtons topBarButtons={topBarButtons} />
        </div>
      </div>
    </div>
  )
}
