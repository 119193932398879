import React from 'react'
import { SmartLink } from '../..'
import clsx from 'clsx'
import type { ImageType } from '../../../templates/Generic'

export const TopNavTitle = ({
  title,
  content,
  link,
  icon,
  isHeader,
  isSubheader,
  hasBorder,
  noWrap,
  headerNoWrap = true,
  limitWidth,
  oneLayer,
}: {
  title: string
  content?: string
  link?: string
  icon?: ImageType
  isHeader?: boolean
  isSubheader?: boolean
  hasBorder?: boolean
  noWrap?: boolean
  headerNoWrap?: boolean
  limitWidth?: boolean
  oneLayer?: boolean
}) => {
  return (
    <div className="flex flex-row items-center">
              {icon?.url && (
          <img
            src={icon.url}
            alt={icon.alt || icon.filename || ''}
            className={clsx('mr-2 inline-block', {
              'h-10 w-10': isSubheader && !oneLayer || isHeader,
              'h-6 w-6': !isHeader,
            })}
          />
        )}
    <div
      className={clsx('flex flex-col gap-0.5', {
        '': isHeader,
        '': isSubheader,
        '': !isHeader && !isSubheader,
        'border-b border-b-gray-500 pb-3': hasBorder,
        'lg:w-[256px] xl:w-[320px]': icon?.url && limitWidth,
        'lg:w-[304px] xl:w-[368px]': !icon?.url && limitWidth
      })}
    >
      <SmartLink
        linkTo={link}
        className={clsx('text-gray-900 no-underline', {
          'text-lg font-medium': isHeader,
          'text-base': isSubheader,
          'text-sm font-normal': !isHeader && !isSubheader,
          'whitespace-nowrap leading-8': headerNoWrap,
          'whitespace-normal': !headerNoWrap,
          'font-MaisonNeueExtended font-bold': !oneLayer,
          'font-MaisonNeue font-medium': oneLayer,
        })}
      >
        {title} {isHeader && <span className="text-base text-blue-700">{`>`}</span>}
      </SmartLink>
      {content && (
        <p
          className={clsx('text-sm font-normal text-gray-600', {
            'xl:whitespace-nowrap': noWrap,
          })}
        >
          {content}
        </p>
      )}
    </div>
    </div>
  )
}
