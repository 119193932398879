import * as React from "react"
 
import { Card, CardContent } from "../../components/ui/card"
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../../components/ui/carousel"
import { UseCaseCard1, UseCaseCard2, UseCaseCard3 } from "./Components/UseCaseCard"
 
export function UseCaseCarousel() {
  return (
    <div className="overflow-hidden gap-0 justify-center items-center px-12 py-16 pt-0 pb-20 mt-0 mb-0 max-w-page md:mx-auto lg:px-24 md:mt-0 md:mb-0 md:pt-0 md:pb-24">
    <Carousel
      opts={{
        align: "start",
      }}
    >
      <CarouselContent className="flex flex-row gap-8 w-auto xl:gap-0 xl:-ml-8">
        <CarouselItem className="basis-[100%] sm:basis-[48%] lg:basis-1/2 xl:basis-1/3"> 
          <div className="flex flex-col gap-12 px-10 py-10 h-full bg-white rounded-3xl xl:ml-8 lg:py-12 lg:px-12 shadow-standard">
            <img className="object-contain h-10" 
            src={"https://www.datocms-assets.com/114273/1737319319-infineologo.png"}> 
            </img>

            <p className="text-lg text-gray-600 lg:text-xl">Infineo is digitizing the $3+ trillion life insurance industry on Provenance Blockchain</p>
          </div>
        </ CarouselItem>
        <CarouselItem className="basis-[100%] sm:basis-[48%] lg:basis-1/2 xl:basis-1/3"> 
          <div className="flex flex-col gap-12 px-10 py-10 w-auto h-full bg-white rounded-3xl xl:ml-8 lg:py-12 lg:px-12 shadow-standard">
            <img className="object-contain h-10" 
            src={"https://www.datocms-assets.com/114273/1737319318-bridgetowerlogo.png"}> 
            </img>

            <p className="text-lg text-gray-600 lg:text-xl">Bridgetower Capital leverages Provenance Blockchain to authenticate AI data, and to ledger private equity assets.</p>
          </div>
        </ CarouselItem>
        <CarouselItem className="basis-[100%] sm:basis-[48%] lg:basis-1/2 xl:basis-1/3"> 
          <div className="flex flex-col gap-12 px-10 py-10 w-auto h-full bg-white rounded-3xl xl:ml-8 lg:py-12 lg:px-12 shadow-standard">
            <img className="object-contain h-10" 
            src={"https://www.datocms-assets.com/114273/1737319318-jpmorganlogo.svg"}> 
            </img>

            <p className="text-lg text-gray-600 lg:text-xl">Enabling active indexing and rebalancing of institutional-grade portfolios across multiple chains, leveraging a Provenance Blockchain private Zone.</p>
          </div>
        </ CarouselItem>
      </CarouselContent>
      <CarouselPrevious className="xl:hidden" />
      <CarouselNext className="xl:hidden" />
    </Carousel>
    </div>
  )
}