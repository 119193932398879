import React, { useEffect, useRef, useState } from 'react'
import { useOnClickOutside } from '../../hooks'
import { TopNavTitle } from './TopNavTitle'
import { TopNavMenu } from './TopNavMenu'
import clsx from 'clsx'

export const TopNav = ({
  show,
  data,
  setShow,
}: {
  show: string
  data: any
  setShow: React.Dispatch<React.SetStateAction<string>>
}) => {
  const wrapperRef = useRef(null)
  useOnClickOutside(wrapperRef, () => {
    setFadeIn(false)
    setTimeout(() => {
      setShow('')
    }, 150)
  })
  const [fadeIn, setFadeIn] = useState(false)

  useEffect(() => {
    if (show) {
      setFadeIn(true)
    } else {
      setFadeIn(false)
    }
  }, [show])

  return data?.dropdownLinks?.length > 0 ? (
    <div
      ref={wrapperRef}
      className={clsx(
        `flex z-50 flex-col gap-8 mt-4 bg-white lg:p-6 lg:left-0 lg:rounded-lg lg:shadow-standard`,
        `transition-all duration-150 ease-in-out`,
        {
          'opacity-0 -translate-y-px': !fadeIn,
          'translate-y-0 opacity-1': fadeIn,
          'static lg:absolute': show,
          'hidden': !show,
        },
      )}
    >
      <TopNavTitle
        title={data.dropdownTitle}
        content={data.dropdownSubtitle}
        link={data.linkto || ''}
        isHeader
        limitWidth
      />
      <TopNavMenu 
      data={data.dropdownLinks} 
      oneColumn
      oneLayer
      headerNoWrap={false}
      smallMargin
      limitWidth/>
    </div>
  ) : null
}
